import React from 'react';
import './RestaurantList.css';

const RestaurantList = ({ restaurants, onRestaurantClick }) => {
    return (
        <div className="restaurant-list">
            {restaurants.map((restaurant, index) => (
                <div key={index} className="restaurant-item" onClick={() => onRestaurantClick(restaurant)}>
                    <img src={restaurant.SearchImage} alt={restaurant.Name} className="restaurant-image"/>
                    <div className="restaurant-name">{restaurant.Name}</div>
                    <div className="restaurant-description">{restaurant.Description}</div>
                    <div className="restaurant-address">{restaurant.Address}</div>
                    <div className="restaurant-phone">{restaurant.PhoneNumber}</div>
                    <div className="restaurant-price">{restaurant.PriceRange}</div>
                </div>
            ))}
        </div>
    );
};

export default RestaurantList;

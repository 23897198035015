import React from 'react';
import './Header.css'; 


function Header() {
  return (
    <div className="header">
      
      <a href="https://www.restomontreal.ca/" className="header-link">
        <img src="/restologo.png" alt="RestoMontreal Logo" className='logo'/>
        <h1 className="header-title">restomontreal</h1>
      </a>

      <form className="search-form">
        <input type="text" className="search-input" placeholder="Search..." />
        <button type="submit" className="search-button" aria-label="Search">🔍</button>
      </form>



      <span className="localization-text">EN | FR</span>
      </div>
  );
}

export default Header;

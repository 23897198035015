import React from 'react';
import './RestaurantDetails.css';

const RestaurantDetails = ({ restaurant }) => {
    if (!restaurant) {
        return <div>Select a restaurant</div>;
    }

    return (
        <div className="restaurant-details">
            <h2>{restaurant.Name}</h2>
            <p>{restaurant.Address}</p>
            <p>{restaurant.PhoneNumber}</p>
            <p>{restaurant.PriceRange}</p>
            <img src={restaurant.SearchImage} alt={restaurant.Name} />
        </div>
    );
};

export default RestaurantDetails;
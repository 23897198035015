import React, { useState, useEffect, useCallback } from "react";
import Header from "./Components/header/Header";
import Filter from "./Components/filters/Filter";
import { GoogleMap, Marker, MarkerClusterer, LoadScript, InfoWindow } from "@react-google-maps/api";
import RestaurantDetails from "./Components/RestaurantDetails/RestaurantDetails";
import RestaurantList from "./Components/RestaurantList/RestaurantList";

const RADIUS  = 10; //--> KM
const MapContainer = () => {
    const [restaurants, setRestaurants] = useState([]);
    const [center, setCenter] = useState({
        lat: 45.581071,
        lng: -73.588496,
    });
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [selectedCluster, setSelectedCluster] = useState(null);
    const [clusters, setClusters] = useState([]);
    const [map, setMap] = useState(null);
    const [selectedCuisine, setSelectedCuisine] = useState(null);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedSort, setSelectedSort] = useState(null);
    const [selectedPriceRange, setSelectedPriceRange] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const handleSortChange = (sort) => {
        setSelectedSort(sort);
    };

    const handlePriceRangeChange = (priceRange) => {
        setSelectedPriceRange(priceRange);
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };


    const handleCuisineChange = (cuisine) => {
        setSelectedCuisine(cuisine);
    };

    const handleFeatureChange = (feature) => {
        setSelectedFeature(feature);
    };
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };


    const getUserLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    console.log("User's position is:", userLocation);
                    setCenter(userLocation);
                    setUserLocation(userLocation);
                    fetchRestaurants(userLocation.lat, userLocation.lng);
                },
                (error) => {
                    console.error("Error obtaining user location: ", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    useEffect(() => {
        getUserLocation();
    }, [getUserLocation]);

    const fetchRestaurants = useCallback(() => {
        if (userLocation) {
            const cuisineId = selectedCuisine ? selectedCuisine.ID : null;
            const cuisineParam = cuisineId ? `&c=${cuisineId}` : '';
            const featureId = selectedFeature ? selectedFeature.ID : null;
            const featureParam = featureId ? `&f=${featureId}` : '';
            const sortParam = selectedSort ? `&correctSortParam=${selectedSort}` : ''; // replace 'correctSortParam' with the correct parameter for sorting
            const priceRangeParam = selectedPriceRange ? `&correctPriceRangeParam=${selectedPriceRange}` : ''; // replace 'correctPriceRangeParam' with the correct parameter for price range filtering
            const timeParam = selectedTime ? `&t=${selectedTime}` : '';
            fetch(`https://map.restomontreal.ca/api/search/?lat=${userLocation.lat}&lon=${userLocation.lng}${cuisineParam}${featureParam}${sortParam}${priceRangeParam}${timeParam}&r=${RADIUS}&lang=en`)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (Array.isArray(data.restaurant)) {
                        setRestaurants(data.restaurant);
                    } else {
                        console.log('Data.restaurant fetched from API is not an array');
                    }
                });
        }
    }, [userLocation, selectedCuisine, selectedFeature, selectedSort, selectedPriceRange, selectedTime]);

    useEffect(() => {
        if (userLocation) {
            fetchRestaurants(userLocation.lat, userLocation.lng);
        }
    }, [userLocation, fetchRestaurants]);

    const renderMarkers = (clusterer) => {
        return restaurants.map((restaurant, index) => (
            <Marker
                key={index}
                title={restaurant.Name}
                position={{ lat: Number(restaurant.Lat), lng: Number(restaurant.Lon) }}
                onClick={() => setSelectedRestaurant(restaurant)}
                clusterer={clusterer}
                animation={selectedRestaurant && selectedRestaurant.Name === restaurant.Name ? window.google.maps.Animation.BOUNCE : null}
            />
        ));
    };

    const handleClusterEnd = (clusterer) => {
        setClusters(clusterer.getClusters());
    };

    const handleRestaurantClick = (restaurant) => {
        setCenter({
            lat: Number(restaurant.Lat),
            lng: Number(restaurant.Lon),
        });
    };

    const MapComponent = () => {
        useEffect(() => {
            if (clusters && map) {
                clusters.forEach((cluster) => {
                    const markers = cluster.getMarkers();

                    map.addListener(cluster, 'click', () => {
                        const restaurants = markers.map((marker) => {
                            return restaurants.find((restaurant) => restaurant.Name === marker.getTitle());
                        });
                        setSelectedCluster(restaurants);
                    });
                });
            }
        }, [clusters, map]);

        return null;
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header />
            <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
                <div style={{ width: '400px' }}>
                    <RestaurantList restaurants={restaurants} onRestaurantClick={handleRestaurantClick} />
                </div>
                <div style={{ flex: 1 }}>
                    <LoadScript googleMapsApiKey="AIzaSyAC9O_xUtKZoK6eiCqCHOQQ8_UqauL6Rfo">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            zoom={14}
                            center={center}
                            onLoad={setMap}
                        >
                            {userLocation && (
                                <Marker
                                    position={userLocation}
                                    icon={{
                                        url: "https://www.gstatic.com/images/icons/material/system_gm/2x/person_black_24dp.png",
                                        scaledSize: new window.google.maps.Size(40, 40),
                                    }}
                                />
                            )}
                            <MarkerClusterer onClusteringEnd={handleClusterEnd}>
                                {clusterer => renderMarkers(clusterer)}
                            </MarkerClusterer>
                            <MapComponent />
                            {selectedRestaurant && (
                                <InfoWindow
                                    position={{ lat: Number(selectedRestaurant.Lat), lng: Number(selectedRestaurant.Lon) }}
                                    options={{ pixelOffset: new window.google.maps.Size(200, 0) }}
                                >
                                    <RestaurantDetails restaurant={selectedRestaurant} />
                                </InfoWindow>
                            )}
                        </GoogleMap>
                    </LoadScript>
                    <Filter selectedCuisine={selectedCuisine}
                            handleCuisineChange={handleCuisineChange}
                            selectedFeature={selectedFeature}
                            handleFeatureChange={handleFeatureChange}
                            selectedSort={selectedSort}
                            handleSortChange={handleSortChange}
                            selectedPriceRange={selectedPriceRange}
                            handlePriceRangeChange={handlePriceRangeChange}
                            selectedTime={selectedTime}
                            handleTimeChange={handleTimeChange}/>
                </div>
            </div>
        </div>
    );
};

export default MapContainer;
import React, { useState, useEffect } from 'react';
import './Filter.css';

const Filter = ({ lat, lon, selectedCuisine, handleCuisineChange, selectedFeature, handleFeatureChange, selectedOption, handleOptionChange,selectedSort, handleSortChange, selectedPriceRange, handlePriceRangeChange, selectedTime, handleTimeChange  }) => {
    const [initialCuisines, setInitialCuisines] = useState([]);
    const [initialFeatures, setInitialFeatures] = useState([]);
    const [initialOptions, setInitialOptions] = useState([]);
    const [initialSort, setInitialSort] = useState([]);
    const [initialPriceRange, setInitialPriceRange] = useState([]);
    const [initialTime, setInitialTime] = useState([]);

    useEffect(() => {
        fetch('https://map.restomontreal.ca/api/category/cuisines/?lang=en')
            .then(response => response.text())
            .then(data => {
                if (data) {
                    const parsedData = JSON.parse(data);
                    setInitialCuisines(parsedData.category.Cuisines);
                }
            })
            .catch((error) => console.error('Error:', error));

        const url = 'https://map.restomontreal.ca/api/category/features/?lang=en';
        console.log('Sending request to:', url);
        fetch(url)
            .then(response => {
                console.log('Received response:', response);
                return response.text();
            })
            .then(data => {
                console.log('Received data:', data);
                if (data) {
                    const parsedData = JSON.parse(data);
                    console.log('Parsed data:', parsedData);
                    const flattenedFeatures = parsedData.category.Features.flat();
                    setInitialFeatures(flattenedFeatures);
                }
            })
            .catch((error) => console.error('Error:', error));
        fetch('https://map.restomontreal.ca/api/category/options/?lang=en')
            .then(response => response.text())
            .then(data => {
                if (data) {
                    const parsedData = JSON.parse(data);
                    const flattenedOptions = parsedData.category.Options.flat();
                    setInitialOptions(flattenedOptions);
                }
            })
            .catch((error) => console.error('Error:', error));
        fetch('https://map.restomontreal.ca/api/category/options/?lang=en')
            .then(response => response.json())
            .then(data => {
                setInitialSort(data.category.Sort);
                setInitialPriceRange(data.category.PriceRange);
                setInitialTime(data.category.Time);
            })
            .catch((error) => console.error('Error:', error));
    }, []);

    const handleCuisineChangeLocal = (e) => {
        const selectedCuisineName = e.target.value;
        if (selectedCuisineName === 'reset') {
            handleCuisineChange(null);
        } else {
            const selectedCuisine = initialCuisines.find(cuisine => cuisine.Name === selectedCuisineName);
            handleCuisineChange(selectedCuisine);
        }
    };

    const handleFeatureChangeLocal = (e) => {
        const selectedFeatureName = e.target.value;
        if (selectedFeatureName === 'reset') {
            handleFeatureChange(null);
        } else {
            const selectedFeature = initialFeatures.find(feature => feature.Name === selectedFeatureName);
            handleFeatureChange(selectedFeature);
        }
    };

    const handleOptionChangeLocal = (e) => {
        const selectedOptionName = e.target.value;
        if (selectedOptionName === 'reset') {
            handleOptionChange(null);
        } else {
            const selectedOption = initialOptions.find(option => option.Name === selectedOptionName);
            handleOptionChange(selectedOption);
        }
    };
    //not sure if this is working as intended
    const handleSortChangeLocal = (e) => {
        const selectedSortId = e.target.value;
        if (selectedSortId === 'reset') {
            handleSortChange(null);
        } else {
            handleSortChange(selectedSortId);
        }
    };

    //not sure if this is working as intended
    const handlePriceRangeChangeLocal = (e) => {
        const selectedPriceRangeId = e.target.value;
        if (selectedPriceRangeId === 'reset') {
            handlePriceRangeChange(null);
        } else {
            handlePriceRangeChange(selectedPriceRangeId);
        }
    };

    const handleTimeChangeLocal = (e) => {
        const selectedTimeId = e.target.value;
        if (selectedTimeId === 'reset') {
            handleTimeChange(null);
        } else {
            handleTimeChange(selectedTimeId);
        }
    };

    return (
        <div className="filter-container">
            <div className="selectWrapper">
                <select className="filter-button" onChange={handleCuisineChangeLocal}>
                    <option value="reset">Cusines</option>
                    {initialCuisines.map((cuisine, index) => (
                        <option key={index} value={cuisine.Name}>
                            {cuisine.Name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="selectWrapper">
                <select className="filter-button" onChange={handleFeatureChangeLocal}>
                    <option value="reset">Features</option>
                    {initialFeatures.map((feature, index) => (
                        <option key={index} value={feature.Name}>
                            {feature.Name}
                        </option>
                    ))}
                </select>
            </div>
            {/*<div className="selectWrapper">*/}
            {/*    <select className="filter-button" onChange={handleOptionChangeLocal}>*/}
            {/*        <option value="reset">Reset</option>*/}
            {/*        {initialOptions.map((option, index) => (*/}
            {/*            <option key={index} value={option.Name}>*/}
            {/*                {option.Name}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*</div>*/}
            <div className="selectWrapper">
            <select className="filter-button" onChange={handleSortChangeLocal}>
                <option value="reset">Sort</option>
                {initialSort.map((sort, index) => (
                    <option key={index} value={sort.ID}>
                        {sort.Name}
                    </option>
                ))}
            </select>
            </div>
            <div className="selectWrapper">
            <select className="filter-button" onChange={handlePriceRangeChangeLocal}>
                <option value="reset">Price Range</option>
                {initialPriceRange.map((priceRange, index) => (
                    <option key={index} value={priceRange.ID}>
                        {priceRange.Name}
                    </option>
                ))}
            </select>
            </div>
            <div className="selectWrapper">
            <select className="filter-button" onChange={handleTimeChangeLocal}>
                <option value="reset">Times</option>
                {initialTime.map((time, index) => (
                    <option key={index} value={time.ID}>
                        {time.Name}
                    </option>
                ))}
            </select>
            </div>

        </div>
    );
};

export default Filter;